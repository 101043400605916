import React from "react";

const Header = () => {
  return (
    <div className="flex w-full p-4 font-semibold text-md gap-8 items-center justify-center">
      <img src="/images/w3k_logo_v1.png" className="h-12" />
    </div>
  );
};

export default Header;
