import React, { useState, useEffect } from "react";
import Container from "../components/Container";
import { ArrowRight, Link2 } from "iconsax-react";
import { Icon } from "@iconify/react";
import useShorten from "../hooks/shorten";
import axios from "axios";

const Transaction = ({ changeDexToken, selectedDexToken }) => {
  const chainExplorers = {
    eth: "https://etherscan.io/tx/",
    sol: "https://solscan.io/tx/",
    base: "https://basescan.org/tx/",
  };

  const [loading, setLoading] = useState(false);
  const [txData, setTxData] = useState([]);

  const shorten = useShorten();

  const fetchTransaction = (first = false) => {
    setLoading(true);
    setTimeout(() => {
      axios
        .get(process.env.REACT_APP_TX_FEED_API_URL, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY,
          },
        })
        .then((res) => {
          setLoading(false);
          res.data.results.forEach((item) => {
            item.price.change = parseFloat(item.price.change.replace("%", ""));
          });
          setTxData(res.data.results);
          if (first) {
            changeDexToken(
              res.data.results[0].chainName,
              res.data.results[0].coin.address
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error fetching transaction data");
          console.log(error);
        });
    }, 3000);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchTransaction();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchTransaction(true);
  }, [])

  return (
    <Container
      title="Transaction"
      containerStyle="xl:w-7/12 w-full"
      childrenStyle="h-[700px] max-h-[700px] w-full font-medium"
      childrenLoading={loading}
      refreshData={fetchTransaction}
      coverDataWhileLoad={false}
    >
      {txData && txData.length > 0 ? (
        <table className="w-full mt-4">
          <thead>
            <tr className="border-b border-[#FFFFFF66]">
              <td className="text-start py-2 px-4">Date</td>
              <td className="text-start py-2 px-4">Coin</td>
              <td className="text-start py-2 px-4">Action</td>
              <td className="text-start py-2 px-4">Chain</td>
              <td className="text-end py-2 px-4">Price</td>
            </tr>
          </thead>
          <tbody>
            {txData.map((item, index) => (
              <tr
                key={index}
                className="hover:bg-[#141619] text-nowrap cursor-pointer"
                onClick={() =>
                  changeDexToken(item.chainName, item.coin.address)
                }
              >
                <td className="text-start py-2 px-4">
                  {new Date(item.date).toLocaleDateString()}
                </td>
                <td className="text-start py-2 px-4">
                  <div className="flex w-full items-center gap-2">
                    <img
                      src={item.coin.image}
                      alt={item.coin.name}
                      className="w-8 h-8"
                    />
                    <div className="flex flex-col w-full justify-center truncate">
                      <div className="flex text-sm gap-1 truncate">
                        <p className="truncate">{item.coin.name}</p>
                        <p className="text-[#FFFFFFAA]">{item.coin.symbol}</p>
                      </div>
                      <a
                        className="flex w-fit text-xs text-[#00FFDD] items-center hover:underline gap-2 cursor-pointer font-normal"
                        href={`${chainExplorers[item.chain]}${
                          item.transactionHash
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="flex gap-1 items-center">
                          <Link2 size="14" />
                          Transaction Hash
                        </p>
                        <p className="flex gap-1 items-center truncate">
                          <ArrowRight size="14" />
                          {shorten(item.transactionHash)}
                        </p>
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-start py-2 px-4">
                  <p
                    className={`rounded-lg py-1 w-12 text-center ${
                      item.action === "buy"
                        ? "bg-[#22C55E33] text-green-500"
                        : "bg-[#FF303033] text-red-500"
                    }`}
                  >
                    {capitalizeFirstLetter(item.action)}
                  </p>
                </td>
                <td className="text-start py-2 px-4">
                  <div className="flex items-center gap-0.5">
                    <Icon
                      icon={`token-branded:${item.chain}`}
                      width="24"
                      height="24"
                    />
                    <p>{item.chain.toUpperCase()}</p>
                  </div>
                </td>
                <td className="text-end py-2 px-4">
                  <div className="flex flex-col">
                    <p>{item.price.value.toFixed(6)}</p>
                    <p
                      className={`${
                        item.price.change > 0
                          ? "text-green-500"
                          : item.price.change < 0
                          ? "text-red-500"
                          : "text-gray-400"
                      }`}
                    >
                      {item.price.change > 0 ? "+" : ""}
                      {item.price.change}%
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && (
          <div className="flex w-full h-full items-center justify-center">
            <p>No data</p>
          </div>
        )
      )}
    </Container>
  );
};

export default Transaction;
