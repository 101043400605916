import { useState, useEffect } from "react";

const CountdownTimer = ({ date, enableComponent, addMinutes }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      const target = new Date(date).getTime() + addMinutes * 60000;
      const now = new Date().getTime();
      const difference = target - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setTimeRemaining({
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        });
      } else {
        setTimeRemaining();
        if (enableComponent) {
          enableComponent(false); // Notify parent that the countdown has ended
        }
      }
    };

    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, [date, enableComponent]);

  return (
    <div className="flex gap-[8px] md:gap-[32px]">
      {timeRemaining && (
        <h1>
          {timeRemaining.days > 0 && `${timeRemaining.days}:`}
          {timeRemaining.hours > 0 && `${timeRemaining.hours}:`}
          {timeRemaining.minutes}:{timeRemaining.seconds}
        </h1>
      )}
    </div>
  );
};

export default CountdownTimer;
