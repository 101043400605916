import React from "react";
import MainPage from "./pages/MainPage";
import Header from "./components/Header";

function App() {
  return (
    <div className="bg-[#0A0B0D] text-white w-screen h-screen overflow-y-auto">
      <Header />
      <MainPage />
    </div>
  );
}

export default App;
