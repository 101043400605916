import React, { useState } from "react";
import Container from "../components/Container";
import Transaction from "../modules/Transaction";
import TopMovers from "../modules/TopMovers";

const MainPage = () => {
  const [token, setToken] = useState({
    chainName: null,
    tokenAddress: null,
  });

  // Have to format image path to match the dexscreener format
  const changeDexToken = (chainName, tokenAddress) => {
    setToken({
      chainName: chainName,
      tokenAddress: tokenAddress,
    });
  };

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <div className="flex flex-col w-full p-4 gap-5">
        <div className="flex flex-col xl:flex-row w-full gap-4">
          <Transaction
            changeDexToken={changeDexToken}
            selectedDexToken={token}
          />
          <TopMovers />
        </div>
        {token.chainName !== null && token.tokenAddress !== null && (
          <Container title="Dexscreener" containerStyle="w-full">
            <div id="dexscreener-embed">
              <iframe
                title="dexscreener"
                src={`https://dexscreener.com/${token.chainName}/${token.tokenAddress}?embed=1&theme=dark`}
              />
            </div>
          </Container>
        )}
      </div>
    </div>
  );
};

export default MainPage;
