import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import CountdownTimer from "./CountdownTimer";

const Container = ({
  title,
  children,
  childrenStyle = "",
  containerStyle = "",
  childrenLoading = false,
  coverDataWhileLoad = true,
  refreshData,
}) => {
  const [refreshDisabled, setRefreshDisabled] = useState(false);
  const [lastRefreshed, setLastRefreshed] = useState(null);

  const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleRefresh = () => {
    setRefreshDisabled(true);
    refreshData();
    setLastRefreshed(new Date());
  };

  return (
    <div className={`border border-[#00FFDD] ${containerStyle}`}>
      <div className="flex flex-col md:flex-row w-full min-h-16 bg-[#00FFDD] py-2 px-4 gap-2 text-md font-semibold text-black items-center justify-between">
        <p>{title}</p>
        <div className="flex items-center gap-2">
          {childrenLoading && (
            <div className="flex justify-center items-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
          {refreshData && (
            <div className="flex w-full gap-3 items-center justify-end">
              <button
                className="flex min-w-[108px] h-full items-center border border-black px-3 py-1 rounded-md disabled:text-gray-500 disabled:border-gray-500 justify-center"
                onClick={handleRefresh}
                disabled={refreshDisabled}
              >
                {refreshDisabled ? (
                  <CountdownTimer
                    date={lastRefreshed}
                    enableComponent={setRefreshDisabled}
                    addMinutes={15}
                  />
                ) : (
                  <p className="flex items-center gap-1">
                    Refresh{" "}
                    <Icon icon="tabler:refresh" width="18" height="18" />
                  </p>
                )}
              </button>
              {lastRefreshed && (
                <div className="flex flex-col">
                  <h2>Last Refreshed:</h2>
                  <p>{formatDateTime(lastRefreshed)}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="relative">
        <div className={`no-scrollbar overflow-x-auto ${childrenStyle}`}>
          {children}
        </div>
        {childrenLoading && coverDataWhileLoad && (
          <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 bg-black opacity-40" />
        )}
      </div>
    </div>
  );
};

export default Container;
