import React, { useState, useEffect } from "react";
import Container from "../components/Container";
import ReactPaginate from "react-paginate";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import axios from "axios";

const TopMovers = () => {
  const [loading, setLoading] = useState(false);
  const [moversData, setMoversData] = useState([]);
  const [moversMeta, setMoversMeta] = useState({});

  const fetchMovers = (page = 1) => {
    setLoading(true);
    setTimeout(() => {
      axios
        .get(process.env.REACT_APP_TOP_MOVERS_API_URL, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY,
          },
          params: {
            page: page,
          },
        })
        .then((res) => {
          setLoading(false);
          setMoversData(res.data.results);
          setMoversMeta({
            page: res.data.page,
            totalPages: res.data.totalPages,
            totalResults: res.data.totalResults,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error fetching transaction data");
          console.log(error);
        });
    }, 3000);
  };

  const handlePageClick = (e) => {
    fetchMovers(e.selected + 1);
  };

  useEffect(() => {
    fetchMovers();
  }, []);

  return (
    <Container
      title="Top Movers"
      containerStyle="xl:w-5/12 w-full"
      childrenStyle="h-[700px] max-h-[700px] w-full font-medium"
      childrenLoading={loading}
      refreshData={() => fetchMovers(moversMeta.page)}
    >
      {moversData && moversData.length > 0 ? (
        <div>
          <table className="w-full mt-4">
            <thead>
              <tr className="border-b border-[#FFFFFF66]">
                <td className="text-start px-4 py-2">Coin</td>
                <td className="text-end px-4 py-2">Volume</td>
                <td className="text-end px-4 py-2">Avg Price</td>
              </tr>
            </thead>
            <tbody>
              {moversData.map((item, index) => (
                <tr key={index}>
                  <td className="text-start px-4 py-3">
                    <div className="flex items-center gap-2 text-sm">
                      <img
                        src={item.logo}
                        alt={item.name}
                        className="w-8 h-8"
                      />
                      <p className="text-[#FFFFFFCC]">{item.symbol}</p>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-end">
                    {item.volume.toFixed(2)}
                  </td>
                  <td className="px-4 py-3 text-end">
                    {item.price.toFixed(6)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {moversMeta.totalResults > 0 && (
            <div className="relative flex w-full p-4">
              <ReactPaginate
                onPageChange={(e) => handlePageClick(e)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={moversMeta.totalPages}
                forcePage={moversMeta.page - 1}
                breakLabel="..."
                previousLabel={<ArrowLeft2 size="16px" />}
                nextLabel={<ArrowRight2 size="16px" />}
                nextClassName="paginator-page-btn absolute right-4 border border-[#FFFFFF33] rounded-md"
                previousClassName="paginator-page-btn absolute right-[58px] border border-[#FFFFFF33] rounded-md"
                containerClassName="flex rounded-xl font-medium gap-2"
                activeClassName="paginator-page-btn bg-[#00FFDD] text-[#000000]"
                pageClassName="paginator-page-btn border border-[#FFFFFF33] rounded-md"
                breakClassName="paginator-page-btn"
              />
            </div>
          )}
        </div>
      ) : (
        !loading && (
          <div className="flex w-full h-full items-center justify-center">
            <p>No data</p>
          </div>
        )
      )}
    </Container>
  );
};

export default TopMovers;
